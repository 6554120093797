"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupService = void 0;
class UserGroupService {
    constructor($window, $log, XucLink) {
        this.$window = $window;
        this.$log = $log;
        this.XucLink = XucLink;
        this._userGroups = undefined;
        this.unInit = () => {
            this.$log.info("Unloading UserGroup service");
            this._userGroups = undefined;
        };
    }
    get userGroups() {
        return this._userGroups;
    }
    init() {
        var _a;
        this.$log.info("Starting UserGroup service");
        this.XucLink.whenLoggedOut().then(this.unInit);
        (_a = this.$window.Cti) === null || _a === void 0 ? void 0 : _a.setHandler(this.$window.Cti.MessageType.USERGROUPS, this.onGetUserGroups.bind(this));
    }
    onGetUserGroups(userGroups) {
        this._userGroups = userGroups;
    }
}
exports.UserGroupService = UserGroupService;
UserGroupService.$inject = [
    "$window",
    "$log",
    "XucLink",
];
