import _ from 'lodash';

export default class ContactsController {

  constructor($rootScope, $scope, $stateParams, XucDirectory, applicationConfiguration, $uibModal, ContactService) {

    $scope.$on('contactSheetUpdated', function() {
      $scope.contactSheet = _.each(XucDirectory.getContactSheetResult(), ContactService.setInitials);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.$on('favoriteContactSheetUpdated', function() {
      $scope.favoriteContactSheet = _.each(XucDirectory.getFavoriteContactSheet(), ContactService.setInitials);
      $scope.headers = XucDirectory.getHeaders().slice(0, 5);
      if (!$rootScope.$$phase) $scope.$apply();
    });

    $scope.$on('DirectoryLookUp', function(event, term) {
      $scope.search(term);
    });

    $scope.search = function(term) {
      Cti.searchContacts(term);
      $scope.showSearch = true;
    };

    $scope.getFavorites = function() {
      Cti.getFavoriteContacts();
    };

    $scope.addFavorite = function(contact, $event) {
      $event.stopPropagation();
      contact.isFavorite = true;
      contact.sources.map((source) => {
        Cti.addFavorite(source.id, source.name);
      });
    };

    $scope.removeFavorite = function(contact, $event) {
      $event.stopPropagation();
      contact.isFavorite = false;
      contact.sources.map((source) => {
        Cti.removeFavorite(source.id, source.name);
      });
    };

    $scope.init = function() {
      $scope.appConfig = applicationConfiguration.getCurrentAppConfig();
      if ($stateParams.showFavorites === false) {
        $scope.showSearch = true;
      } else {
        $scope.getFavorites();
        $scope.showSearch = false;
      }

      if (typeof($stateParams.search) != 'undefined') {
        $scope.search($stateParams.search);
      }
      $scope.appConfig = applicationConfiguration.getCurrentAppConfig();
    };

    $scope.removePopUpContainer = () => {
      angular.element(document.getElementsByClassName('angularjs-toast')).remove();
    };

    $scope.openContactSheet = (contact) => {
      if (document.getElementsByClassName('contact-sheet-modal').length === 0) {
        $scope.removePopUpContainer();
        $uibModal.open({
          templateUrl: 'assets/javascripts/xchelper/controllers/contact-sheet.controller.html',
          controller: 'contactSheetController',
          windowClass: 'contact-sheet-modal',
          backdrop: false,
          resolve: {
            contact : () => contact
          },
        }).result.then(() => {}, () => {});
      }

    };

    $scope.actionsProperties = ContactService.getActionsProperties();

    $scope.ordering = ['Edit', 'ShareLink', 'Mail', 'Chat', 'VideoInvite', 'Video', 'AudioInvite', 'Call'];

    $scope.init();
  }
}
