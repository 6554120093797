"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupController = void 0;
class UserGroupController {
    constructor($scope, $rootScope, $state, $translate, UserGroupService) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$translate = $translate;
        this.UserGroupService = UserGroupService;
        this.groups = undefined;
        this.initializeGroups();
    }
    initializeGroups() {
        this.groups = this.UserGroupService.userGroups;
    }
}
exports.UserGroupController = UserGroupController;
UserGroupController.$inject = [
    "$scope",
    "$rootScope",
    "$state",
    "$translate",
    "UserGroupService",
];
