import _ from 'lodash';

export default class PersonalContactController {

  constructor($scope, $state, $uibModal, $uibModalStack, $log, personalContact, XucDirectory, contactId, contactData) {
    this.$scope = $scope;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.$uibModalStack = $uibModalStack;
    this.$log = $log;
    this.personalContact = personalContact;
    this.XucDirectory = XucDirectory;
    this.contactData = contactData;
    this.contactId = contactId;

    $scope.accordionStatus = {
      name: false,
      number: false
    };

    this.populate();
  }

  populate() {
    const fill = (contactData) => {
      angular.forEach(contactData, (value, fieldName) => {
        this.$scope[fieldName] = value;
      });
    };

    fill(this.contactData);

    if (this.contactId) {
      this.personalContact.get(this.contactId).then((response) => {
        this.$scope.favorite = this.isFavorite();
        fill(response.data);
      });
    }
  }

  isFavorite() {
    return _.some(this.XucDirectory.getFavoriteContactSheet(), c => {
      const maybeMeetingRoomId = c.sources.find((source) => {
        return source.name === 'personal';
      });
      if (maybeMeetingRoomId && maybeMeetingRoomId.id) {
        return maybeMeetingRoomId.id === this.contactId;
      } else {
        return false;
      }
    });
  }

  noOneValid(form, validityGroup) {
    for (var inputField of validityGroup) {
      if (form[inputField].$viewValue) {
        if (form[inputField].$viewValue.trim().length != 0) {
          return false;
        }
      }
    }
    return true;
  }

  validatePersonalContact(form) {
    let names = ['lastname', 'firstname'];
    let number = ['number', 'mobile', 'fax'];
    return this.noOneValid(form, names) || this.noOneValid(form, number);
  }

  savePersonalContact() {
    let form = this.$scope.personalContactForm;
    var pc = {};

    angular.forEach(form, (field, fieldName) => {
      if (!_.startsWith(fieldName, '$')) {
        pc[fieldName] = field.$viewValue;
      }
    });
    this.save(pc);
  }

  save(pc) {
    this.$scope.ajaxRequest = {
      ongoing: true,
      error: false
    };

    var promise = this.contactId ? this.personalContact.update(pc, this.contactId) : this.personalContact.add(pc);

    promise.then((response) => {
      if (this.$scope.favorite && !this.isFavorite()) {
        Cti.addFavorite(response.data.id, 'personal');
      }
      if (!this.$scope.favorite && this.isFavorite()) {
        Cti.removeFavorite(response.data.id, 'personal');
      }
      this.close('interface.favorites');
    }, (response) => {
      this.$log.error(response.data);
      if (response.data.error) {
        this.$scope.ajaxRequest['error' + response.data.error] = true;
      }
      else {
        this.$scope.ajaxRequest.error = true;
      }
    }).finally(() => {
      this.$scope.ajaxRequest.ongoing = false;
    });
  }

  remove() {
    this.personalContact.remove(this.contactId).then(() => {
      this.close('interface.favorites');
    });
  }

  close(dest) {
    this.$state.go(dest);
    this.$uibModalStack.dismissAll();
  }

  dismiss() {
    this.contactId ? this.close('interface.favorites') :
      this.contactData ? this.close('interface.history') :
        this.close('interface.menu');
  }

  confirmDelete() {
    this.$uibModal.open({
      templateUrl: 'confirmDialog.html',
      size: 'sm',
      scope: this.$scope,
      controller: 'ConfirmationModalController',
      controllerAs: 'modalCtrl',
      resolve: {
        params: () => {
          return {item: 'CONTACT'};
        }
      }
    });
  }
}