export default function mediaDevices($window, $q) {
  var deferred;

  function _getAudioOutput() {
    deferred = $q.defer();
    $window.navigator.mediaDevices.enumerateDevices().then((result) => {
      deferred.resolve(
        result.filter((item) => {
          return item.kind === "audiooutput";
        })
      );
    }).catch(err => {
      console.error(err);
      deferred.reject(err);
    });
    return deferred.promise;
  }

  function _getAudioDevices() {
    deferred = $q.defer();
    $window.navigator.mediaDevices.enumerateDevices().then((result) => {
      deferred.resolve(
        result.filter((item) => {
          return item.kind === "audioinput" || item.kind === "audiooutput";
        })
      );
    }).catch(err => {
      console.error(err);
      deferred.reject(err);
    });
    return deferred.promise;
  }

  return {
    getAudioOutput: _getAudioOutput,
    getAudioDevices: _getAudioDevices,
  };
}
