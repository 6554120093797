
export default class ContactSheetController {

  constructor($scope, contact, ContactService, $uibModalInstance, toast, $translate, XucLink) {
    $scope['contact'] = contact;


    $scope.contactSheetisSectionEmpty = (contact) => {
      contact.details.forEach(section => {
        section.collapsed=true;
        section.fields.forEach(field => {
          if (field.data !== "") {
            section.collapsed = false;
          }
        });
      });
    };

    $scope.toggleSection = (section) => {
      section.collapsed = !section.collapsed;
    };

    $scope.contactSheetisSectionEmpty(contact);

    $scope.contactSheetIsMeetingRoom = (contact) => contact.isMeetingroom;

    $scope.contactSheetAddFavorite = (contact, $event) => {
      $event.stopPropagation();
      contact.isFavorite = true;
      contact.sources.map((source) => {
        Cti.addFavorite(source.id, source.name);
      });
    };

    $scope.contactSheetRemoveFavorite = (contact, $event) => {
      $event.stopPropagation();
      contact.isFavorite = false;
      contact.sources.map((source) => {
        Cti.removeFavorite(source.id, source.name);
      });
    };

    $scope.contactSheetGetPhoneStateBackColor = (contact) => ContactService.getPhoneStateBackColor(contact);

    $scope.contactSheetGetPhoneStateLabel = (contact) => ContactService.getPhoneStateLabel(contact);

    $scope.contactSheetOnClose = () => $uibModalInstance.dismiss(true);

    const getTranslationType = (field) => {
      switch (field) {
      case 'meetingroom':
        return $translate.instant('MEETINGROOM_COPIED');
      case 'PhoneNumber':
        return $translate.instant('PHONE_COPIED');
      case 'Mail':
        return $translate.instant('EMAIL_COPIED');
      default:
        return $translate.instant('VALUE_COPIED', {value: field.name});
      }
    };

    $scope.contactSheetClipboardCopy = (field, $event) => {
      navigator.clipboard.writeText(field.data);
      $scope.contactSheetClipboardPopup(field, $event);
    };

    $scope.contactSheetClipboardPopup = (field, $event) => {
      $event.stopPropagation();
      toast({
        duration: 3000,
        message: getTranslationType(field),
        className: 'alert-copied',
        position: 'center',
        container: '#toast-container-modal-id'
      });
    };

    $scope.contactSheetGetFieldDataFormatted = (contact, field) => ContactService.getFieldDataFormatted(contact, field);

    $scope.contactSheetGetFieldMailToLink = (contact) => ContactService.generateMailToLink(contact);

    $scope.contactSheetMakeACall = (phoneNumber , $event) => {
      ContactService.makeACall($scope.contact, $event, phoneNumber);
      $scope.contactSheetOnClose();
    };

    $scope.getContextualPhoneClass = (contact) => ContactService.getContextualPhoneClass(contact);
    $scope.getContextualPhoneTooltip = (contact) => (ContactService.getContextualPhoneClass(contact) + "-tooltip").replaceAll("-","_").toUpperCase();
    $scope.execContextualPhoneAction = (contact, number, $event) => {
      ContactService.execContextualPhoneAction(contact, number, $event);
      $scope.contactSheetOnClose();
    };

    XucLink.whenLoggedOut().then($scope.contactSheetOnClose());

    $scope.actionsProperties = ContactService.getActionsProperties($scope.contactSheetOnClose);

    $scope.ordering = ['Edit', 'ShareLink', 'Mail', 'Chat', 'VideoInvite', 'Video', 'AudioInvite', 'Call'];

  }
}
